var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":'UserInfo',"size":"xl","headerText":_vm.$t('Users.data'),"headerIcon":_vm.user.icon},on:{"opened":function($event){return _vm.getUserDetails()}}},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg,"image":_vm.exceptionImg}}):_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.fullCode,"title":_vm.$t('Users.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userTypeNameCurrent,"title":_vm.$t('Users.type'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userNameAr,"title":_vm.$t('Users.nameAr'),"imgName":'users.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userNameEn,"title":_vm.$t('Users.nameEn'),"imgName":'users.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userNameUnd,"title":_vm.$t('Users.nameUnd'),"imgName":'users.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6 phone-number',"value":_vm.user.userPhoneWithCC,"title":_vm.$t('Users.phone'),"imgName":'phone.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userEmail,"title":_vm.$t('Users.email'),"imgName":'email.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userName,"title":_vm.$t('Users.userName'),"imgName":'user.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.establishmentRoleData
          ? _vm.user.establishmentRoleData.establishmentRoleNameCurrent
          : '',"title":_vm.$t('EstablishmentRoles.name'),"imgName":'type.svg'}}),(_vm.user.userProfileData)?[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.userGenderNameCurrent,"title":_vm.$t('Users.gender'),"imgName":'gender.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.userBirthPlaceNameCurrent,"title":_vm.$t('Users.userBirthPlace'),"imgName":'address.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.jobGradeData.systemComponentsHierarchyData
            .systemComponentHierarchyNameCurrent,"title":_vm.$t('SystemComponentsHierarchies.jobGrade'),"imgName":'systemComponentsHierarchies.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.jobGradeData.systemComponentData
            .systemComponentNameCurrent,"title":_vm.$t('SystemComponents.jobGrade'),"imgName":'systemComponents.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.jobGradeData.jobGradeNameCurrent,"title":_vm.$t('JobGrades.name'),"imgName":'jobGrade.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.geographicalDistributionData
            .systemComponentsHierarchyData.systemComponentHierarchyNameCurrent,"title":_vm.$t('SystemComponentsHierarchies.geographicalDistribution'),"imgName":'systemComponentsHierarchies.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.geographicalDistributionData
            .systemComponentData.systemComponentNameCurrent,"title":_vm.$t('SystemComponents.geographicalDistribution'),"imgName":'systemComponents.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.geographicalDistributionData
            .geographicalDistributionNameCurrent,"title":_vm.$t('GeographicalDistributions.name'),"imgName":'geographicalDistributions.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
            _vm.user.userProfileData.userBirthDate,
            _vm.user.userProfileData.userBirthTime
          ),"title":_vm.$t('Users.userBirthDateTime'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.militaryStatusTypeData
            .militaryStatusTypeNameCurrent,"title":_vm.$t('MilitaryStatusTypes.name'),"imgName":'militaryStatus.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
            _vm.user.userProfileData.militaryStatuseTypeStartDate,
            _vm.user.userProfileData.militaryStatuseTypeStartTime
          ),"title":_vm.$t('Users.militaryStatuseTypeStartDateTime'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
            _vm.user.userProfileData.militaryStatuseTypeEndDate,
            _vm.user.userProfileData.militaryStatuseTypeEndTime
          ),"title":_vm.$t('Users.militaryStatuseTypeEndDateTime'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.userAddressCurrent,"title":_vm.$t('Users.address'),"imgName":'address.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.userGeneralNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6 phone-number',"value":_vm.user.userProfileData.userPhone1WithCC,"title":_vm.$t('Users.userPhone1'),"imgName":'phone.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.userPhone1WhatsAppStatus
            ? _vm.$t('activeStatus')
            : _vm.$t('disActiveStatus'),"title":_vm.$t('Users.whatsAppStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6 phone-number',"value":_vm.user.userProfileData.userPhone2WithCC,"title":_vm.$t('Users.userPhone2'),"imgName":'phone.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.userPhone2WhatsAppStatus
            ? _vm.$t('activeStatus')
            : _vm.$t('disActiveStatus'),"title":_vm.$t('Users.whatsAppStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6 phone-number',"value":_vm.user.userProfileData.userPhone3WithCC,"title":_vm.$t('Users.userPhone3'),"imgName":'phone.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.userPhone3WhatsAppStatus
            ? _vm.$t('activeStatus')
            : _vm.$t('disActiveStatus'),"title":_vm.$t('Users.whatsAppStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.nationalityCountryData
            .nationalityCountryNameCurrent,"title":_vm.$t('countries.nationality'),"imgName":'countries.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.userEmail,"title":_vm.$t('Users.userEmail'),"imgName":'email.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.socialStatuseData.socialStatuseNameCurrent,"title":_vm.$t('SocialStatuses.name'),"imgName":'socialStatuses.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.userSocialStatuseNotes,"title":_vm.$t('Users.userSocialStatuseNotes'),"imgName":'notes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.insuranceCompanyData
            .insuranceCompanyNameCurrent,"title":_vm.$t('InsuranceCompanies.name'),"imgName":'insuranceCompanies.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.userInsuranceNumber,"title":_vm.$t('Users.insuranceNumber'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.bankData.bankNameCurrent,"title":_vm.$t('Banks.name'),"imgName":'banks.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.userBankAccountNumber,"title":_vm.$t('Users.bankAccountNumber'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.userCitizenshipTypeNameCurrent,"title":_vm.$t('Users.citizenship'),"imgName":'citizenship.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.parentsSocialStatusData
            .socialStatuseNameCurrent,"title":_vm.$t('Users.parentsSocialStatus'),"imgName":'socialStatuses.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.fatherDeathTypeNameCurrent,"title":_vm.$t('Users.fatherDeathType'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.motherDeathTypeNameCurrent,"title":_vm.$t('Users.motherDeathType'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.user.userProfileData.userNationalNumber,"title":_vm.$t('Users.nationalID'),"imgName":'nationalNumber.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.user.userProfileData.religionNameCurrent,"title":_vm.$t('Religions.name'),"imgName":'religions.svg'}})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }